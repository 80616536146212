$font-color: #181b2f;
$dark-white: #eff1f8;
$gray: #e0e4f0;
$dark-gray: #8798ab;
$gray-line: #d7dceb;
$blue-link: #248cff;

$white: #fff;
$red-notification: #ff0000;
$main-gradient: linear-gradient(270deg, #58c3ff 0%, #1bddde 100%);

$main-color: linear-gradient(90.16deg, #3300ff 0.14%, #ff0099 99.88%);

// active normal color
$color-primary: #3300ff;
$color-secondary: #ff0099;

.dark {
    $font-color: #141718;
}

// White colors
$graphite900: #181b2f;
$black900: #0f1215;
$gray900: #8798ab;
$green900: #57c05c;
$pink900: #ff0099;
$blue900: #248cff;
$purple: #5034ff;
$background: #eff1f8;
$white: #fff;

// Dark colors
$backgroundDark: #141718;
$dark900: #080d1b;
$line900: #1f2425;
$gray900Dark: #6c7275;
$panel1: #232627;
$panel2: #2b2f30;
$white900: rgb(239, 241, 248);
$primary: linear-gradient(90.16deg, #3300ff 0.14%, #ff0099 99.88%);

$bracket-wtabs: 1000px;
$bracket-wmobile: 400px;

$mobile: 375px;
$mobileSize: 375;
$container-mobile: 15px;
$controller-mobile: 32px;
$controller-mobile-bottom: 38px;

// $tablet: 768px;
$tablet: 1000px;
$tabletSize: 1000;
$container-tablet: 39px;
$controller-tablet: 39px;
$controller-tablet-bottom: 38px;

:export {
    mainColor: $main-color;
    darkWhite: $dark-white;
    activeFillColor: $white;
    inactiveFillColor: $dark-gray;
    activeColorTablet: linear-gradient(90.16deg, #3300ff 0.14%, #ff0099 99.88%);
    tabletSize: $tabletSize;
    mobileSize: $mobileSize;
}
