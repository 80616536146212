.App {
    text-align: center;
    direction: rtl;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}

@property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}
@keyframes counter {
    from {
        --num: 0;
    }
    to {
        --num: 40;
    }
}

body {
    background: rgb(255,255,255);
    background: linear-gradient(26deg, rgba(255,255,255,1) 0%, rgba(44,182,175,1) 46%, rgba(186,150,182,1) 66%, rgba(221,13,140,1) 100%);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
}
:before,
:after {
    --tw-content: "";
}
html,
:host {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        Segoe UI Symbol, "Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    line-height: inherit;
}
hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}
abbr:where([title]) {
    text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}
a {
    color: inherit;
    text-decoration: inherit;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
        monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
button,
select {
    text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
:-moz-focusring {
    outline: auto;
}
:-moz-ui-invalid {
    box-shadow: none;
}
progress {
    vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
summary {
    display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}
fieldset {
    margin: 0;
    padding: 0;
}
legend {
    padding: 0;
}
ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
dialog {
    padding: 0;
}
textarea {
    resize: vertical;
}
input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
}
button,
[role="button"] {
    cursor: pointer;
}
:disabled {
    cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}
img,
video {
    max-width: 100%;
    height: auto;
}
[hidden] {
    display: none;
}
html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: MV Faseyha, Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
input,
textarea {
    border-radius: 1rem;
    border-width: 1px;
    border-color: #0b184c1a;
    padding: 0.625rem 1.25rem;
    font-size: 19px;
}
input::placeholder,
textarea::placeholder {
    color: #0b184c1a;
}
*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}
.pointer-events-none {
    pointer-events: none;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0;
}
.inset-x-0 {
    left: 0;
    right: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.top-0 {
    top: 0;
}
.top-full {
    top: 100%;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-50 {
    z-index: 50;
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.-mt-1 {
    margin-top: -0.25rem;
}
.-mt-2 {
    margin-top: -0.5rem;
}
.-mt-4 {
    margin-top: -1rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-auto {
    margin-left: auto;
}
.mr-14 {
    margin-right: 3.5rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-12 {
    margin-top: 3rem;
}
.mt-16 {
    margin-top: 4rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-20 {
    margin-top: 5rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.box-border {
    box-sizing: border-box;
}
.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
.line-clamp-none {
    overflow: visible;
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: none;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.aspect-square {
    aspect-ratio: 1 / 1;
}
.h-10 {
    height: 2.5rem;
}
.h-11 {
    height: 2.75rem;
}
.h-12 {
    height: 3rem;
}
.h-14 {
    height: 3.5rem;
}
.h-4 {
    height: 1rem;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-7 {
    height: 1.75rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[100px\] {
    height: 100px;
}
.h-\[120px\] {
    height: 120px;
}
.h-\[18px\] {
    height: 18px;
}
.h-\[276px\] {
    height: 276px;
}
.h-\[30px\] {
    height: 30px;
}
.h-\[34px\] {
    height: 34px;
}
.h-\[3px\] {
    height: 3px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[42px\] {
    height: 42px;
}
.h-\[50px\] {
    height: 50px;
}
.h-\[5px\] {
    height: 5px;
}
.h-\[60px\] {
    height: 60px;
}
.h-\[62px\] {
    height: 62px;
}
.h-\[78px\] {
    height: 78px;
}
.h-auto {
    height: auto;
}
.h-full {
    height: 100%;
}
.h-screen {
    height: 100vh;
}
.max-h-60 {
    max-height: 15rem;
}
.max-h-\[90vh\] {
    max-height: 90vh;
}
.max-h-full {
    max-height: 100%;
}
.min-h-\[80vh\] {
    min-height: 80vh;
}
.min-h-full {
    min-height: 100%;
}
.w-1\/2 {
    width: 50%;
}
.w-10 {
    width: 2.5rem;
}
.w-11 {
    width: 2.75rem;
}
.w-12 {
    width: 3rem;
}
.w-14 {
    width: 3.5rem;
}
.w-4 {
    width: 1rem;
}
.w-5\/6 {
    width: 83.333333%;
}
.w-6 {
    width: 1.5rem;
}
.w-7 {
    width: 1.75rem;
}
.w-9 {
    width: 2.25rem;
}
.w-\[113px\] {
    width: 113px;
}
.w-\[120px\] {
    width: 120px;
}
.w-\[153px\] {
    width: 153px;
}
.w-\[180px\] {
    width: 180px;
}
.w-\[18px\] {
    width: 18px;
}
.w-\[330px\] {
    width: 330px;
}
.w-\[3px\] {
    width: 3px;
}
.w-\[40px\] {
    width: 40px;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[5px\] {
    width: 5px;
}
.w-\[60px\] {
    width: 60px;
}
.w-\[62px\] {
    width: 62px;
}
.w-\[72px\] {
    width: 72px;
}
.w-\[74px\] {
    width: 74px;
}
.w-auto {
    width: auto;
}
.w-fit {
    width: fit-content;
}
.w-full {
    width: 100%;
}
.max-w-7xl {
    max-width: 80rem;
}
.max-w-\[127px\] {
    max-width: 127px;
}
.max-w-\[1360px\] {
    max-width: 1360px;
}
.max-w-\[300px\] {
    max-width: 300px;
}
.max-w-\[350px\] {
    max-width: 350px;
}
.max-w-\[357px\] {
    max-width: 357px;
}
.max-w-\[408px\] {
    max-width: 408px;
}
.max-w-\[764px\] {
    max-width: 764px;
}
.max-w-\[842px\] {
    max-width: 842px;
}
.max-w-\[940px\] {
    max-width: 940px;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-none {
    flex: none;
}
.shrink-0 {
    flex-shrink: 0;
}
.flex-grow {
    flex-grow: 1;
}
.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
.scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
.scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.animate-spin {
    animation: spin 1s linear infinite;
}
.cursor-pointer {
    cursor: pointer;
}
.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
}
.snap-center {
    scroll-snap-align: center;
}
.list-decimal {
    list-style-type: decimal;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-11 {
    gap: 2.75rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-7 {
    gap: 1.75rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-9 {
    gap: 2.25rem;
}
.gap-\[10px\] {
    gap: 10px;
}
.gap-\[146px\] {
    gap: 146px;
}
.gap-\[18px\] {
    gap: 18px;
}
.gap-\[35px\] {
    gap: 35px;
}
.gap-\[56px\] {
    gap: 56px;
}
.gap-\[5px\] {
    gap: 5px;
}
.gap-\[60px\] {
    gap: 60px;
}
.gap-\[7px\] {
    gap: 7px;
}
.self-stretch {
    align-self: stretch;
}
.justify-self-end {
    justify-self: end;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-y-auto {
    overflow-y: auto;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre-line {
    white-space: pre-line;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-2xl {
    border-radius: 1rem;
}
.rounded-\[100px\] {
    border-radius: 100px;
}
.rounded-\[10px\] {
    border-radius: 10px;
}
.rounded-\[13\.33px\] {
    border-radius: 13.33px;
}
.rounded-\[20px\] {
    border-radius: 20px;
}
.rounded-\[32px\] {
    border-radius: 32px;
}
.rounded-\[48px\] {
    border-radius: 48px;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.rounded-b-\[20px\] {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.rounded-bl-\[20px\] {
    border-bottom-left-radius: 20px;
}
.rounded-br-\[20px\] {
    border-bottom-right-radius: 20px;
}
.border {
    border-width: 1px;
}
.border-2 {
    border-width: 2px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-\[10px\] {
    border-bottom-width: 10px;
}
.border-r-4 {
    border-right-width: 4px;
}
.border-t {
    border-top-width: 1px;
}
.border-dashed {
    border-style: dashed;
}
.border-\[\#7A809B\] {
    --tw-border-opacity: 1;
    border-color: rgb(122 128 155 / var(--tw-border-opacity));
}
.border-\[\#CCCFD9\] {
    --tw-border-opacity: 1;
    border-color: rgb(204 207 217 / var(--tw-border-opacity));
}
.border-\[\#DAEBEB\],
.border-\[\#daebeb\] {
    --tw-border-opacity: 1;
    border-color: rgb(218 235 235 / var(--tw-border-opacity));
}
.border-\[\#ebdada\] {
    --tw-border-opacity: 1;
    border-color: rgb(235 218 218 / var(--tw-border-opacity));
}
.border-\[rgba\(101\,143\,205\,0\.14\)\] {
    border-color: #658fcd24;
}
.border-\[rgba\(101\,143\,205\,0\.2\)\] {
    border-color: #658fcd33;
}
.border-\[rgba\(101\,143\,205\,0\.27\)\] {
    border-color: #658fcd45;
}
.border-\[rgba\(11\,24\,76\,0\.07\)\] {
    border-color: #0b184c12;
}
.border-\[rgba\(11\,24\,76\,0\.1\)\] {
    border-color: #0b184c1a;
}
.border-\[rgba\(11\,79\,101\,0\.2\)\] {
    border-color: #0b4f6533;
}
.border-\[rgba\(201\,114\,192\,0\.14\)\] {
    border-color: #c972c024;
}
.border-\[rgba\(201\,114\,192\,0\.3\)\] {
    border-color: #c972c04d;
}
.border-\[rgba\(235\,250\,255\,0\.91\)\] {
    border-color: #ebfaffe8;
}
.border-\[rgba\(254\,192\,15\,0\.14\)\] {
    border-color: #fec00f24;
}
.border-\[rgba\(254\,192\,15\,0\.24\)\] {
    border-color: #fec00f3d;
}
.border-\[rgba\(3\,67\,55\,0\.2\)\] {
    border-color: #03433733;
}
.border-\[rgba\(67\,44\,3\,0\.2\)\] {
    border-color: #432c0333;
}
.border-\[rgba\(71\,3\,78\,0\.07\)\] {
    border-color: #47034e12;
}
.border-\[rgba\(71\,3\,78\,0\.2\)\] {
    border-color: #47034e33;
}
.border-\[rgba\(85\,199\,199\,0\.14\)\] {
    border-color: #55c7c724;
}
.border-\[rgba\(85\,199\,199\,0\.24\)\] {
    border-color: #55c7c73d;
}
.border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity));
}
.border-gray\/10 {
    border-color: #0b184c1a;
}
.border-gray\/40 {
    border-color: #0b184c66;
}
.border-gray\/\[7\%\] {
    border-color: #0b184c12;
}
.border-neutral-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.border-primary-400 {
    --tw-border-opacity: 1;
    border-color: rgb(53 108 253 / var(--tw-border-opacity));
}
.bg-\[\#6D7494\] {
    --tw-bg-opacity: 1;
    background-color: rgb(109 116 148 / var(--tw-bg-opacity));
}
.bg-\[\#BBF1EF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(187 241 239 / var(--tw-bg-opacity));
}
.bg-\[\#C4C8D4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(196 200 212 / var(--tw-bg-opacity));
}
.bg-\[\#F0F5F5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 245 / var(--tw-bg-opacity));
}
.bg-\[\#e2eeff4d\] {
    background-color: #e2eeff4d;
}
.bg-\[\#f0f5f5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 245 / var(--tw-bg-opacity));
}
.bg-\[\#f5f0f0\] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 240 240 / var(--tw-bg-opacity));
}
.bg-\[rgba\(11\,24\,76\,0\.07\)\] {
    background-color: #0b184c12;
}
.bg-\[rgba\(4\,174\,248\,0\.05\)\] {
    background-color: #04aef80d;
}
.bg-black\/25 {
    background-color: #00000040;
}
.bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.bg-blue-light {
    background-color: #e2eeff4d;
}
.bg-gray\/\[2\%\] {
    background-color: #0b184c05;
}
.bg-gray\/\[4\%\] {
    background-color: #0b184c0a;
}
.bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.bg-primary-500\/\[2\%\] {
    background-color: #084bfc05;
}
.bg-primary-500\/\[4\%\] {
    background-color: #084bfc0a;
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white\/20 {
    background-color: #fff3;
}
.bg-white\/90 {
    background-color: #ffffffe6;
}
.bg-answered-status {
    background-image: linear-gradient(334deg, #7fe1b8 -54.12%, #e5fffc 103.83%);
}
.bg-complain {
    background-image: linear-gradient(307deg, #fffbe8 1.71%, #fdf2f3 87.51%);
}
.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}
.bg-inquiry {
    background-image: linear-gradient(307deg, #ebfff7 1.71%, #eaffea 87.51%);
}
.bg-login-button {
    background-image: linear-gradient(95deg, rgba(44,182,175,1) -41.7%, rgba(221,13,140,1) 104.1%);
}
.bg-pending-status {
    background-image: linear-gradient(334deg, #d8dadf -54.12%, #f8f9fa 103.83%);
}
.bg-primary-button {
    background-image: linear-gradient(95deg, #00fff4 -41.7%, #084bfc 104.1%);
}
.bg-solution {
    background-image: linear-gradient(307deg, #e6fff9 1.71%, #e2eeff 87.51%);
}
.bg-suggestion {
    background-image: linear-gradient(307deg, #ffebee 1.71%, #e8e4ff 87.51%);
}
.from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.via-white {
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position),
        var(--tw-gradient-to);
}
.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.object-contain {
    object-fit: contain;
}
.object-cover {
    object-fit: cover;
}
.p-2 {
    padding: 0.5rem;
}
.p-2\.5 {
    padding: 0.625rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-8 {
    padding: 2rem;
}
.p-\[2px\] {
    padding: 2px;
}
.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}
.px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-\[18px\] {
    padding-left: 18px;
    padding-right: 18px;
}
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-\[5px\] {
    padding-top: 5px;
    padding-bottom: 5px;
}
.py-\[7px\] {
    padding-top: 7px;
    padding-bottom: 7px;
}
.py-\[9px\] {
    padding-top: 9px;
    padding-bottom: 9px;
}
.pb-0 {
    padding-bottom: 0;
}
.pb-1 {
    padding-bottom: 0.25rem;
}
.pb-10 {
    padding-bottom: 2.5rem;
}
.pb-12 {
    padding-bottom: 3rem;
}
.pb-14 {
    padding-bottom: 3.5rem;
}
.pb-16 {
    padding-bottom: 4rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pb-32 {
    padding-bottom: 8rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-40 {
    padding-bottom: 10rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-7 {
    padding-bottom: 1.75rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pb-9 {
    padding-bottom: 2.25rem;
}
.pb-\[14px\] {
    padding-bottom: 14px;
}
.pb-\[27px\] {
    padding-bottom: 27px;
}
.pb-\[7px\] {
    padding-bottom: 7px;
}
.pl-5 {
    padding-left: 1.25rem;
}
.pl-\[22px\] {
    padding-left: 22px;
}
.pr-5 {
    padding-right: 1.25rem;
}
.pr-6 {
    padding-right: 1.5rem;
}
.pr-9 {
    padding-right: 2.25rem;
}
.pr-\[18px\] {
    padding-right: 18px;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-12 {
    padding-top: 3rem;
}
.pt-14 {
    padding-top: 3.5rem;
}
.pt-16 {
    padding-top: 4rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-20 {
    padding-top: 5rem;
}
.pt-24 {
    padding-top: 6rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-3\.5 {
    padding-top: 0.875rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-7 {
    padding-top: 1.75rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-\[10px\] {
    padding-top: 10px;
}
.pt-\[110px\] {
    padding-top: 110px;
}
.pt-\[116px\] {
    padding-top: 116px;
}
.pt-\[13px\] {
    padding-top: 13px;
}
.pt-\[76px\] {
    padding-top: 76px;
}
.pt-\[84px\] {
    padding-top: 84px;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.align-middle {
    vertical-align: middle;
}
.align-sub {
    vertical-align: sub;
}
.font-aamu {
    font-family: MV Aaamu FK, Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
.font-faseyha {
    font-family: MV Faseyha, Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
.font-inter {
    font-family: Inter, MV Faseyha, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
.font-waheed {
    font-family: MV A Waheed, Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}
.text-\[17px\] {
    font-size: 17px;
}
.text-\[18px\] {
    font-size: 18px;
}
.text-\[19px\] {
    font-size: 19px;
}
.text-\[20px\] {
    font-size: 20px;
}
.text-\[21px\] {
    font-size: 21px;
}
.text-\[22px\] {
    font-size: 22px;
}
.text-\[23px\] {
    font-size: 23px;
}
.text-\[26px\] {
    font-size: 26px;
}
.text-\[28px\] {
    font-size: 28px;
}
.text-\[30px\] {
    font-size: 30px;
}
.text-\[32px\] {
    font-size: 32px;
}
.text-\[34px\] {
    font-size: 34px;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.uppercase {
    text-transform: uppercase;
}
.not-italic {
    font-style: normal;
}
.leading-10 {
    line-height: 2.5rem;
}
.leading-5 {
    line-height: 1.25rem;
}
.leading-6 {
    line-height: 1.5rem;
}
.leading-8 {
    line-height: 2rem;
}
.leading-9 {
    line-height: 2.25rem;
}
.leading-\[22px\] {
    line-height: 22px;
}
.leading-\[23px\] {
    line-height: 23px;
}
.leading-\[28px\] {
    line-height: 28px;
}
.leading-\[29px\] {
    line-height: 29px;
}
.leading-\[30px\] {
    line-height: 30px;
}
.leading-\[32px\] {
    line-height: 32px;
}
.leading-\[36px\] {
    line-height: 36px;
}
.leading-\[40px\] {
    line-height: 40px;
}
.leading-\[48px\] {
    line-height: 48px;
}
.leading-\[58px\] {
    line-height: 58px;
}
.leading-loose {
    line-height: 2;
}
.tracking-\[-0\.18px\] {
    letter-spacing: -0.18px;
}
.tracking-\[0\.28px\] {
    letter-spacing: 0.28px;
}
.tracking-\[0\.2px\] {
    letter-spacing: 0.2px;
}
.tracking-\[0\.32px\] {
    letter-spacing: 0.32px;
}
.tracking-\[1\%\] {
    letter-spacing: 1%;
}
.tracking-wide {
    letter-spacing: 0.025em;
}
.text-\[\#034337\] {
    --tw-text-opacity: 1;
    color: rgb(3 67 55 / var(--tw-text-opacity));
}
.text-\[\#064444\] {
    --tw-text-opacity: 1;
    color: rgb(6 68 68 / var(--tw-text-opacity));
}
.text-\[\#0b4f65\] {
    --tw-text-opacity: 1;
    color: rgb(11 79 101 / var(--tw-text-opacity));
}
.text-\[\#432c03\] {
    --tw-text-opacity: 1;
    color: rgb(67 44 3 / var(--tw-text-opacity));
}
.text-\[\#454545\] {
    --tw-text-opacity: 1;
    color: rgb(69 69 69 / var(--tw-text-opacity));
}
.text-\[\#47034e\] {
    --tw-text-opacity: 1;
    color: rgb(71 3 78 / var(--tw-text-opacity));
}
.text-\[\#4E4E4E\] {
    --tw-text-opacity: 1;
    color: rgb(78 78 78 / var(--tw-text-opacity));
}
.text-\[\#55C7C7\] {
    --tw-text-opacity: 1;
    color: rgb(85 199 199 / var(--tw-text-opacity));
}
.text-\[\#658FCD\] {
    --tw-text-opacity: 1;
    color: rgb(101 143 205 / var(--tw-text-opacity));
}
.text-\[\#6A646B\] {
    --tw-text-opacity: 1;
    color: rgb(106 100 107 / var(--tw-text-opacity));
}
.text-\[\#6D7494\] {
    --tw-text-opacity: 1;
    color: rgb(109 116 148 / var(--tw-text-opacity));
}
.text-\[\#6a646b\] {
    --tw-text-opacity: 1;
    color: rgb(106 100 107 / var(--tw-text-opacity));
}
.text-\[\#716D71\] {
    --tw-text-opacity: 1;
    color: rgb(113 109 113 / var(--tw-text-opacity));
}
.text-\[\#7F9595\],
.text-\[\#7f9595\] {
    --tw-text-opacity: 1;
    color: rgb(127 149 149 / var(--tw-text-opacity));
}
.text-\[\#858BA5\] {
    --tw-text-opacity: 1;
    color: rgb(133 139 165 / var(--tw-text-opacity));
}
.text-\[\#910f0f\] {
    --tw-text-opacity: 1;
    color: rgb(145 15 15 / var(--tw-text-opacity));
}
.text-\[\#949494\] {
    --tw-text-opacity: 1;
    color: rgb(148 148 148 / var(--tw-text-opacity));
}
.text-\[\#C972C0\] {
    --tw-text-opacity: 1;
    color: rgb(201 114 192 / var(--tw-text-opacity));
}
.text-\[\#FEC00F\] {
    --tw-text-opacity: 1;
    color: rgb(254 192 15 / var(--tw-text-opacity));
}
.text-\[rgba\(11\,24\,76\,0\.60\)\] {
    color: #0b184c99;
}
.text-\[rgba\(11\,79\,101\,1\)\] {
    color: #0b4f65;
}
.text-\[rgba\(3\,67\,55\,1\)\] {
    color: #034337;
}
.text-\[rgba\(67\,44\,3\,1\)\] {
    color: #432c03;
}
.text-\[rgba\(71\,3\,78\,1\)\] {
    color: #47034e;
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-gray {
    --tw-text-opacity: 1;
    color: rgb(11 24 76 / var(--tw-text-opacity));
}
.text-gray\/10 {
    color: #0b184c1a;
}
.text-gray\/30 {
    color: #0b184c4d;
}
.text-gray\/40 {
    color: #0b184c66;
}
.text-gray\/50 {
    color: #0b184c80;
}
.text-gray\/70 {
    color: #0b184cb3;
}
.text-gray\/80 {
    color: #0b184ccc;
}
.text-gray\/90 {
    color: #0b184ce6;
}
.text-light-gray {
    --tw-text-opacity: 1;
    color: rgb(148 148 148 / var(--tw-text-opacity));
}
.text-medium-gray {
    --tw-text-opacity: 1;
    color: rgb(136 136 136 / var(--tw-text-opacity));
}
.text-neutral-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.text-primary-500 {
    --tw-text-opacity: 1;
    color: rgb(8 75 252 / var(--tw-text-opacity));
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.opacity-70 {
    opacity: 0.7;
}
.shadow-\[0_20px_30px_0_rgba\(193\,160\,68\,0\.07\)\] {
    --tw-shadow: 0 20px 30px 0 rgba(193, 160, 68, 0.07);
    --tw-shadow-colored: 0 20px 30px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-\[0px_20px_30px_0px_\#C1A04412\] {
    --tw-shadow: 0px 20px 30px 0px #c1a04412;
    --tw-shadow-colored: 0px 20px 30px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-\[0px_32px_36px_0px_\#0000000A\] {
    --tw-shadow: 0px 32px 36px 0px #0000000a;
    --tw-shadow-colored: 0px 32px 36px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-bottom-overlay {
    --tw-shadow: 0px -15px 13.5px 0px rgba(73, 76, 83, 0.01);
    --tw-shadow-colored: 0px -15px 13.5px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-comment-box-inset {
    --tw-shadow: 0px -167px 45.5px -50px #fbfafc inset;
    --tw-shadow-colored: inset 0px -167px 45.5px -50px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
        0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.backdrop-blur-\[15px\] {
    --tw-backdrop-blur: blur(15px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
        var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
        var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
        var(--tw-backdrop-sepia);
}
.backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
        var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
        var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
        var(--tw-backdrop-sepia);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
        opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.duration-100 {
    transition-duration: 0.1s;
}
.duration-200 {
    transition-duration: 0.2s;
}
.duration-300 {
    transition-duration: 0.3s;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
@font-face {
    font-family: MV Aaamu FK;
    src: url(/src/assets/fonts/MVAammufk.ttf);
}
@font-face {
    font-family: MV A Waheed;
    src: url(/src/assets/fonts/MVAWaheed.ttf);
}
@font-face {
    font-family: MV Faseyha;
    src: url(/src/assets/fonts/MVFaseyha.otf);
}
.webkit-backdrop-blur-15 {
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}
.last\:border-0:last-child {
    border-width: 0px;
}
.hover\:border-\[\#7A809B\]:hover {
    --tw-border-opacity: 1;
    border-color: rgb(122 128 155 / var(--tw-border-opacity));
}
.hover\:border-gray\/30:hover {
    border-color: #0b184c4d;
}
.hover\:bg-\[\#6D7494\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(109 116 148 / var(--tw-bg-opacity));
}
.hover\:bg-\[\#E3F2F6\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(227 242 246 / var(--tw-bg-opacity));
}
.hover\:bg-\[\#E7F9FD\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(231 249 253 / var(--tw-bg-opacity));
}
.hover\:bg-gray\/5:hover {
    background-color: #0b184c0d;
}
.hover\:bg-gray\/\[8\%\]:hover {
    background-color: #0b184c14;
}
.hover\:bg-primary-500\/\[4\%\]:hover {
    background-color: #084bfc0a;
}
.hover\:bg-transparent:hover {
    background-color: transparent;
}
.hover\:bg-login-button:hover {
    background-image: linear-gradient(95deg, #00fff4 -41.7%, #084bfc 104.1%);
}
.hover\:bg-primary-button-hover:hover {
    background-image: linear-gradient(95deg, #00fff4 -68.95%, #084bfc 85.4%);
}
.hover\:text-\[\#E5FFFE\]:hover {
    --tw-text-opacity: 1;
    color: rgb(229 255 254 / var(--tw-text-opacity));
}
.hover\:text-\[rgba\(11\,24\,76\,0\.80\)\]:hover {
    color: #0b184ccc;
}
.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:shadow-complain:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(193, 160, 68, 0.25);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-complain-submission:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(193, 160, 68, 0.12);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-inquiry:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(85, 199, 199, 0.25);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-inquiry-submission:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(85, 199, 199, 0.12);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-login-button:hover {
    --tw-shadow: 0px 15px 60px 0px rgba(8, 75, 252, 0.3);
    --tw-shadow-colored: 0px 15px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-solution:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(101, 143, 205, 0.25);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-solution-submission:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(101, 143, 205, 0.12);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-suggestion:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(93, 68, 193, 0.25);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.hover\:shadow-suggestion-submission:hover {
    --tw-shadow: 0px 40px 60px 0px rgba(93, 68, 193, 0.12);
    --tw-shadow-colored: 0px 40px 60px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.enabled\:text-white:enabled {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.disabled\:border-none:disabled {
    border-style: none;
}
.disabled\:border-gray\/10:disabled {
    border-color: #0b184c1a;
}
.disabled\:bg-\[\#E3F2F6\]:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(227 242 246 / var(--tw-bg-opacity));
}
.disabled\:bg-primary-button-disabled:disabled {
    background-image: linear-gradient(122deg, #e3f2f6 -27.38%, #e9eef8 89.88%);
}
.disabled\:text-disabled:disabled {
    color: #0b184c33;
}
.group:hover .group-hover\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:border-\[rgba\(101\,143\,205\,1\)\] {
    border-color: #658fcd;
}
.group:hover .group-hover\:border-\[rgba\(201\,114\,192\,1\)\] {
    border-color: #c972c0;
}
.group:hover .group-hover\:border-\[rgba\(254\,192\,15\,1\)\] {
    border-color: #fec00f;
}
.group:hover .group-hover\:border-\[rgba\(85\,199\,199\,1\)\] {
    border-color: #55c7c7;
}
.group:hover .group-hover\:border-transparent {
    border-color: transparent;
}
.group:hover .group-hover\:bg-\[rgba\(101\,143\,205\,1\)\] {
    background-color: #658fcd;
}
.group:hover .group-hover\:bg-\[rgba\(201\,114\,192\,1\)\] {
    background-color: #c972c0;
}
.group:hover .group-hover\:bg-\[rgba\(254\,192\,15\,1\)\] {
    background-color: #fec00f;
}
.group:hover .group-hover\:bg-\[rgba\(85\,199\,199\,1\)\] {
    background-color: #55c7c7;
}
.group:hover .group-hover\:text-\[\#CDE7FF\] {
    --tw-text-opacity: 1;
    color: rgb(205 231 255 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-\[\#E5FFE6\] {
    --tw-text-opacity: 1;
    color: rgb(229 255 230 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-\[\#FFEDCD\] {
    --tw-text-opacity: 1;
    color: rgb(255 237 205 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-\[\#FFFFFF\],
.group:hover .group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
    .sm\:mr-auto {
        margin-right: auto;
    }
    .sm\:inline {
        display: inline;
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:items-center {
        align-items: center;
    }
    .sm\:gap-2 {
        gap: 0.5rem;
    }
    .sm\:gap-3 {
        gap: 0.75rem;
    }
    .sm\:gap-8 {
        gap: 2rem;
    }
    .sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .sm\:pt-0 {
        padding-top: 0;
    }
}
@media (min-width: 768px) {
    .md\:-mt-4 {
        margin-top: -1rem;
    }
    .md\:mt-20 {
        margin-top: 5rem;
    }
    .md\:inline {
        display: inline;
    }
    .md\:flex {
        display: flex;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-7 {
        height: 1.75rem;
    }
    .md\:h-\[50px\] {
        height: 50px;
    }
    .md\:h-\[60px\] {
        height: 60px;
    }
    .md\:max-h-\[80vh\] {
        max-height: 80vh;
    }
    .md\:w-7 {
        width: 1.75rem;
    }
    .md\:w-\[214px\] {
        width: 214px;
    }
    .md\:w-\[434px\] {
        width: 434px;
    }
    .md\:w-\[50px\] {
        width: 50px;
    }
    .md\:w-\[60px\] {
        width: 60px;
    }
    .md\:w-auto {
        width: auto;
    }
    .md\:w-full {
        width: 100%;
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:items-start {
        align-items: flex-start;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:justify-end {
        justify-content: flex-end;
    }
    .md\:justify-between {
        justify-content: space-between;
    }
    .md\:gap-10 {
        gap: 2.5rem;
    }
    .md\:gap-14 {
        gap: 3.5rem;
    }
    .md\:gap-3 {
        gap: 0.75rem;
    }
    .md\:gap-5 {
        gap: 1.25rem;
    }
    .md\:gap-6 {
        gap: 1.5rem;
    }
    .md\:gap-7 {
        gap: 1.75rem;
    }
    .md\:gap-8 {
        gap: 2rem;
    }
    .md\:gap-\[42px\] {
        gap: 42px;
    }
    .md\:gap-\[84px\] {
        gap: 84px;
    }
    .md\:gap-\[88px\] {
        gap: 88px;
    }
    .md\:self-auto {
        align-self: auto;
    }
    .md\:rounded-\[20px\] {
        border-radius: 20px;
    }
    .md\:p-10 {
        padding: 2.5rem;
    }
    .md\:p-4 {
        padding: 1rem;
    }
    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .md\:px-14 {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    .md\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .md\:px-24 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .md\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .md\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .md\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .md\:py-36 {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
    .md\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .md\:pb-14 {
        padding-bottom: 3.5rem;
    }
    .md\:pb-16 {
        padding-bottom: 4rem;
    }
    .md\:pb-20 {
        padding-bottom: 5rem;
    }
    .md\:pb-24 {
        padding-bottom: 6rem;
    }
    .md\:pb-8 {
        padding-bottom: 2rem;
    }
    .md\:pb-9 {
        padding-bottom: 2.25rem;
    }
    .md\:pb-\[17px\] {
        padding-bottom: 17px;
    }
    .md\:pt-10 {
        padding-top: 2.5rem;
    }
    .md\:pt-16 {
        padding-top: 4rem;
    }
    .md\:pt-5 {
        padding-top: 1.25rem;
    }
    .md\:pt-6 {
        padding-top: 1.5rem;
    }
    .md\:pt-\[116px\] {
        padding-top: 116px;
    }
    .md\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    .md\:text-\[19px\] {
        font-size: 19px;
    }
    .md\:text-\[21px\] {
        font-size: 21px;
    }
    .md\:text-\[23px\] {
        font-size: 23px;
    }
    .md\:text-\[26px\] {
        font-size: 26px;
    }
    .md\:text-\[28px\] {
        font-size: 28px;
    }
    .md\:text-\[31px\] {
        font-size: 31px;
    }
    .md\:text-\[32px\] {
        font-size: 32px;
    }
    .md\:text-\[34px\] {
        font-size: 34px;
    }
    .md\:text-\[52px\] {
        font-size: 52px;
    }
    .md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .md\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .md\:leading-10 {
        line-height: 2.5rem;
    }
    .md\:leading-\[56px\] {
        line-height: 56px;
    }
    .md\:leading-\[82px\] {
        line-height: 82px;
    }
    .md\:leading-relaxed {
        line-height: 1.625;
    }
    .md\:tracking-\[0\.32px\] {
        letter-spacing: 0.32px;
    }
    .group:hover .md\:group-hover\:block {
        display: block;
    }
}
@media (min-width: 1024px) {
    .lg\:mr-12 {
        margin-right: 3rem;
    }
    .lg\:hidden {
        display: none;
    }
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:flex-row {
        flex-direction: row;
    }
    .lg\:items-center {
        align-items: center;
    }
    .lg\:justify-start {
        justify-content: flex-start;
    }
    .lg\:gap-6 {
        gap: 1.5rem;
    }
    .lg\:gap-8 {
        gap: 2rem;
    }
    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .lg\:px-14 {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    .lg\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .lg\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .lg\:pb-0 {
        padding-bottom: 0;
    }
    .lg\:pb-20 {
        padding-bottom: 5rem;
    }
    .lg\:pt-12 {
        padding-top: 3rem;
    }
    .lg\:pt-14 {
        padding-top: 3.5rem;
    }
    .lg\:text-right {
        text-align: right;
    }
    .lg\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    .lg\:text-\[21px\] {
        font-size: 21px;
    }
    .lg\:text-\[23px\] {
        font-size: 23px;
    }
    .lg\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .lg\:leading-8 {
        line-height: 2rem;
    }
    .lg\:leading-\[56px\] {
        line-height: 56px;
    }
    .lg\:tracking-\[-0\.23px\] {
        letter-spacing: -0.23px;
    }
}
@media (min-width: 1280px) {
    .xl\:gap-6 {
        gap: 1.5rem;
    }
    .xl\:p-28 {
        padding: 7rem;
    }
}
