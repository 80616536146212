.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .preloader {
        max-width: 53px;
        max-height: 53px;
        animation: rotateAnimation 1s linear infinite;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(359deg);
    }
}

.overlap {
    position: absolute;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
