@import "/src/styles/variables";
.container {
    & > h5 {
        // @include text(Montserrat, 600, 15px, $font-color);
        margin-bottom: 12px;
    }

    &.inline {
        display: flex;
        gap: 7px;
        align-items: center;

        & > h5 {
            margin-bottom: 0;
        }
    }

    .select-dropdown {
        position: relative;
        display: flex; // inline-block;
        max-width: 100%;

        &.active {
            transition: all ease-in-out 0.5s;
            box-shadow:
                0px 33px 138px rgba(0, 0, 0, 0.05),
                0px 15px 64px rgba(0, 0, 0, 0.04),
                0px 9px 36px rgba(0, 0, 0, 0.04),
                0px 5px 23px rgba(0, 0, 0, 0.03),
                0px 4px 14px rgba(0, 0, 0, 0.03),
                0px 2px 8px rgba(0, 0, 0, 0.02),
                0px 1px 4px rgba(0, 0, 0, 0.01);
            border-radius: 8px;
        }

        &__button {
            width: 100%;
            max-width: 100%;
            justify-content: space-between;

            padding: 12px;
            background: $background;
            border: 1px solid #e6e9f1;
            border-radius: 12px;
            cursor: pointer;
            // width: 355px;
            height: 54px;
            text-align: left;
            position: relative;
            transition: all ease-in-out 0.3s;
            display: flex;
            align-items: center;
            gap: 10px;

            & > span {
                // @include text(Montserrat, 600, 15px, $font-color);
            }

            &::after {
                // content: url(../../../assets/icons/arrow-small__top.png);
                position: absolute;
                color: $font-color;
                top: 18.5px;
                right: 24.18px;
                transition: transform 0.2s ease;
                transform: rotate(180deg);
            }

            &.active {
                border: none;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                background: #fff;

                &::after {
                    transform: rotate(0deg);
                }
            }

            &:focus {
                outline: none;
            }
        }

        &__list {
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            max-height: 300px;
            overflow: auto;
            margin: 0;
            padding: 0;
            list-style-type: none;
            opacity: 0;
            pointer-events: none;
            transform-origin: top left;
            transform: scale(1, 0);
            transition: all ease-in-out 0.3s;
            z-index: 100;

            &.active {
                opacity: 1;
                pointer-events: auto;
                transform: scale(1, 1);
                border-radius: 8px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &-new {
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                background: $dark-white;
                // @include text(Montserrat, 600, 15px, $font-color);
                cursor: pointer;

                & > span {
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    background: $gray900;
                }
            }
        }

        &__list-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 12px;
            height: 54px;
            background: #fff;
            // @include text(Montserrat, 600, 15px, $font-color);
            cursor: pointer;
            transition: all ease-in-out 0.13s;

            &:hover {
                background: $dark-white;
            }
        }
    }

    &[data-theme="dark"] {
        &.container {
            & > h5 {
                color: $white900;
            }

            & .select-dropdown {
                &__button {
                    background: $panel1;
                    border-color: $line900;

                    & > span {
                        color: $white900;
                    }
                }

                &__list {
                    &-new {
                        background: $backgroundDark;

                        & > p {
                            color: $white900;
                        }
                    }
                }

                &__list-item {
                    background: $panel1;
                    color: $white900;
                }
            }
        }
    }
}
